import revive_payload_client_4sVQNw7RlN from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_initialize_SdiW4su0KB from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/plugins/01.initialize.js";
import _02_locale_rSr51TF4Sm from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/plugins/02.locale.js";
import _03_merchantAccess_bQvUcbMq2u from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/plugins/03.merchantAccess.js";
import register_nuxt_link_ton6JZyGwc from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/plugins/register-nuxt-link.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_initialize_SdiW4su0KB,
  _02_locale_rSr51TF4Sm,
  _03_merchantAccess_bQvUcbMq2u,
  register_nuxt_link_ton6JZyGwc
]