import { default as indexsvh3as32alMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/index.vue?macro=true";
import { default as _91id_93qplMdnmqDVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as indexhH3gO65wOEMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_93UEVkI6T3jnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexX2PHKHcC4GMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93eBLv0HzTgiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexQe2kJ5pxaNMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexwzqamvartOMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/index.vue?macro=true";
import { default as indexhVlQRIc77sMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexrBfNtRuhoSMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93bVWzpIoP1XMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_938CaCYAZNEhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indextA3DgyDCFAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexope61jAY6iMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93zIHHT1P0ZpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexpbq0zJfHT0Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93LX9rYDdQC7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexOTuOLYSXw5Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsxWOm1byNZzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/merchants.vue?macro=true";
import { default as _91accountId_93TiOHChNavrMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as indexnLRUBj7RQ9Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_93gyLlpn70oYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indexyoB7OROUh5Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as _91configurationId_93pVAyKAlCcxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/bookkeeping/[configurationId].vue?macro=true";
import { default as indexC8tieuy8zXMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/bookkeeping/index.vue?macro=true";
import { default as credentialsC1FfZOAl28Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as index5yZvDBxbwFMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as order_45providersHjqOPx2kPWMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/order-providers.vue?macro=true";
import { default as _91id_931ee12XdOZEMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93Hj145pSQkHMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexmgEKlUvgeBMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexdUgVaDJrcRMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_93M4GDw5fIoQMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as index7c6hXQwOUSMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexFbiBg4qo2nMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as indexgU2mjHobvyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id]/index.vue?macro=true";
import { default as lognpgV8S9fXuMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id]/log.vue?macro=true";
import { default as _91id_93BS3HTDYeW1Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexFX5rIk0cjdMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexPjJgf8q2PsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexsBMwvLUu2SMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methodsnrg97TZU7EMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as configurations5sMx8eiF94Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reservations/configurations.vue?macro=true";
import { default as indexS9gl11aNryMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as configurations6yrX9rmR8hMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reviews/configurations.vue?macro=true";
import { default as index6DB5lzoS4KMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as _91id_93QkpZgrrjxhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-categories/[id].vue?macro=true";
import { default as _91id_93WpTrzY5BFGMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-items/[id].vue?macro=true";
import { default as _91id_93cKJseGZVBvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-statuses/[id].vue?macro=true";
import { default as stockKib9brzosqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingWUR3scVhbIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_93Quoo98HYYyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as indext5iATorndYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as indexjb6du2QHSOMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as indextxrx9AbuNNMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/kitchen/index.vue?macro=true";
import { default as _91kitchenStationId_93Pw47uE6jpSMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue?macro=true";
import { default as _91printerId_93keLv8EMNMlMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/printers/[printerId].vue?macro=true";
import { default as index83vWeGwCzoMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/printers/index.vue?macro=true";
import { default as stockfkWBWTzOzFMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93lAF147RpGBMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexyWntmRXPBDMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexB6RtZTwHlPMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93zLoS556VqMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexahLFDeMjJAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as index7vRZZn1toAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as log4wGZlnOpstMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationn6QgWIoVwoMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_93Kexp4rb7wfMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as index97PFsYMYvgMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as index1pTW3pC4ZAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/profile/index.vue?macro=true";
import { default as downloadimpehwiDAzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/download.vue?macro=true";
import { default as efficiencydiNRZyGZJwMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/efficiency.vue?macro=true";
import { default as indexZTcUmHYoLxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/index.vue?macro=true";
import { default as production1DxfG19jrxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/production.vue?macro=true";
import { default as tipsZUGEvm7zXnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/tips.vue?macro=true";
import { default as index2cH31B9EsmMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settings/index.vue?macro=true";
import { default as indexm1OPuzePS7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasonsjBaVkESK4aMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as cash_45transferstgqTG7dIKGMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/cash-transfers.vue?macro=true";
import { default as _91id_93yXmsdQdMosMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/categories/[id].vue?macro=true";
import { default as indexRAj6aFsknAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/categories/index.vue?macro=true";
import { default as index9T84xJDuskMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/index.vue?macro=true";
import { default as indexKFEIRmkm7dMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logrZuTmkNBwgMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordershdYu5R5DJLMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93pGQWIGnCGIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexR7WcrIDqimMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93U1jphQYTUHMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93hd1XPCGnT4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as index4iAhA7GtZVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93iJ1fXWe8byMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexoLPDtdwlwgMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_93wmCVLINy6TMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/orders/[id].vue?macro=true";
import { default as indexKJ3MBysQHKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/orders/index.vue?macro=true";
import { default as channel_45settlementszB3EefEBjoMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexGDoov4MetKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logdtgAt6ATnXMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersvW1ZGX7iosMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as voucher_45exportszymXZsWN1IMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/voucher-exports.vue?macro=true";
import { default as _91id_93TnfYtXcpI7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as closing_45errors9vRREQXOK2Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexyD4S5kJfAjMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: indexsvh3as32alMeta?.name ?? "index",
    path: indexsvh3as32alMeta?.path ?? "/",
    meta: indexsvh3as32alMeta || {},
    alias: indexsvh3as32alMeta?.alias || [],
    redirect: indexsvh3as32alMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qplMdnmqDVMeta?.name ?? "inventory-bundle-choices-id",
    path: _91id_93qplMdnmqDVMeta?.path ?? "/inventory/bundle-choices/:id()",
    meta: _91id_93qplMdnmqDVMeta || {},
    alias: _91id_93qplMdnmqDVMeta?.alias || [],
    redirect: _91id_93qplMdnmqDVMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundle-choices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhH3gO65wOEMeta?.name ?? "inventory-bundle-choices",
    path: indexhH3gO65wOEMeta?.path ?? "/inventory/bundle-choices",
    meta: indexhH3gO65wOEMeta || {},
    alias: indexhH3gO65wOEMeta?.alias || [],
    redirect: indexhH3gO65wOEMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundle-choices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93UEVkI6T3jnMeta?.name ?? "inventory-bundles-id",
    path: _91id_93UEVkI6T3jnMeta?.path ?? "/inventory/bundles/:id()",
    meta: _91id_93UEVkI6T3jnMeta || {},
    alias: _91id_93UEVkI6T3jnMeta?.alias || [],
    redirect: _91id_93UEVkI6T3jnMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexX2PHKHcC4GMeta?.name ?? "inventory-bundles",
    path: indexX2PHKHcC4GMeta?.path ?? "/inventory/bundles",
    meta: indexX2PHKHcC4GMeta || {},
    alias: indexX2PHKHcC4GMeta?.alias || [],
    redirect: indexX2PHKHcC4GMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: _91menuCategoryId_93eBLv0HzTgiMeta?.name ?? "inventory-menus-id-categories-menuCategoryId",
    path: _91menuCategoryId_93eBLv0HzTgiMeta?.path ?? "/inventory/menus/:id()/categories/:menuCategoryId()",
    meta: _91menuCategoryId_93eBLv0HzTgiMeta || {},
    alias: _91menuCategoryId_93eBLv0HzTgiMeta?.alias || [],
    redirect: _91menuCategoryId_93eBLv0HzTgiMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/[id]/categories/[menuCategoryId].vue").then(m => m.default || m)
  },
  {
    name: indexQe2kJ5pxaNMeta?.name ?? "inventory-menus-id",
    path: indexQe2kJ5pxaNMeta?.path ?? "/inventory/menus/:id()",
    meta: indexQe2kJ5pxaNMeta || {},
    alias: indexQe2kJ5pxaNMeta?.alias || [],
    redirect: indexQe2kJ5pxaNMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwzqamvartOMeta?.name ?? "inventory-menus",
    path: indexwzqamvartOMeta?.path ?? "/inventory/menus",
    meta: indexwzqamvartOMeta || {},
    alias: indexwzqamvartOMeta?.alias || [],
    redirect: indexwzqamvartOMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/menus/index.vue").then(m => m.default || m)
  },
  {
    name: indexhVlQRIc77sMeta?.name ?? "inventory-modifiers",
    path: indexhVlQRIc77sMeta?.path ?? "/inventory/modifiers",
    meta: indexhVlQRIc77sMeta || {},
    alias: indexhVlQRIc77sMeta?.alias || [],
    redirect: indexhVlQRIc77sMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938CaCYAZNEhMeta?.name ?? undefined,
    path: _91id_938CaCYAZNEhMeta?.path ?? "/inventory/modifiers/modifier-groups/:id()",
    meta: _91id_938CaCYAZNEhMeta || {},
    alias: _91id_938CaCYAZNEhMeta?.alias || [],
    redirect: _91id_938CaCYAZNEhMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexrBfNtRuhoSMeta?.name ?? "inventory-modifiers-modifier-groups-id",
    path: indexrBfNtRuhoSMeta?.path ?? "",
    meta: indexrBfNtRuhoSMeta || {},
    alias: indexrBfNtRuhoSMeta?.alias || [],
    redirect: indexrBfNtRuhoSMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91modifierId_93bVWzpIoP1XMeta?.name ?? "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: _91modifierId_93bVWzpIoP1XMeta?.path ?? "modifiers/:modifierId()",
    meta: _91modifierId_93bVWzpIoP1XMeta || {},
    alias: _91modifierId_93bVWzpIoP1XMeta?.alias || [],
    redirect: _91modifierId_93bVWzpIoP1XMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextA3DgyDCFAMeta?.name ?? "inventory-modifiers-modifier-groups",
    path: indextA3DgyDCFAMeta?.path ?? "/inventory/modifiers/modifier-groups",
    meta: indextA3DgyDCFAMeta || {},
    alias: indextA3DgyDCFAMeta?.alias || [],
    redirect: indextA3DgyDCFAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/modifiers/modifier-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexope61jAY6iMeta?.name ?? "inventory-products",
    path: indexope61jAY6iMeta?.path ?? "/inventory/products",
    meta: indexope61jAY6iMeta || {},
    alias: indexope61jAY6iMeta?.alias || [],
    redirect: indexope61jAY6iMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zIHHT1P0ZpMeta?.name ?? "inventory-products-product-categories-id",
    path: _91id_93zIHHT1P0ZpMeta?.path ?? "/inventory/products/product-categories/:id()",
    meta: _91id_93zIHHT1P0ZpMeta || {},
    alias: _91id_93zIHHT1P0ZpMeta?.alias || [],
    redirect: _91id_93zIHHT1P0ZpMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/product-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpbq0zJfHT0Meta?.name ?? "inventory-products-product-categories",
    path: indexpbq0zJfHT0Meta?.path ?? "/inventory/products/product-categories",
    meta: indexpbq0zJfHT0Meta || {},
    alias: indexpbq0zJfHT0Meta?.alias || [],
    redirect: indexpbq0zJfHT0Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/product-categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LX9rYDdQC7Meta?.name ?? "inventory-products-products-id",
    path: _91id_93LX9rYDdQC7Meta?.path ?? "/inventory/products/products/:id()",
    meta: _91id_93LX9rYDdQC7Meta || {},
    alias: _91id_93LX9rYDdQC7Meta?.alias || [],
    redirect: _91id_93LX9rYDdQC7Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOTuOLYSXw5Meta?.name ?? "inventory-products-products",
    path: indexOTuOLYSXw5Meta?.path ?? "/inventory/products/products",
    meta: indexOTuOLYSXw5Meta || {},
    alias: indexOTuOLYSXw5Meta?.alias || [],
    redirect: indexOTuOLYSXw5Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/inventory/products/products/index.vue").then(m => m.default || m)
  },
  {
    name: merchantsxWOm1byNZzMeta?.name ?? "merchants",
    path: merchantsxWOm1byNZzMeta?.path ?? "/merchants",
    meta: merchantsxWOm1byNZzMeta || {},
    alias: merchantsxWOm1byNZzMeta?.alias || [],
    redirect: merchantsxWOm1byNZzMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/merchants.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gyLlpn70oYMeta?.name ?? undefined,
    path: _91id_93gyLlpn70oYMeta?.path ?? "/point-of-sale/account-configurations/:id()",
    meta: _91id_93gyLlpn70oYMeta || {},
    alias: _91id_93gyLlpn70oYMeta?.alias || [],
    redirect: _91id_93gyLlpn70oYMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91accountId_93TiOHChNavrMeta?.name ?? "point-of-sale-account-configurations-id-accounts-accountId",
    path: _91accountId_93TiOHChNavrMeta?.path ?? "accounts/:accountId()",
    meta: _91accountId_93TiOHChNavrMeta || {},
    alias: _91accountId_93TiOHChNavrMeta?.alias || [],
    redirect: _91accountId_93TiOHChNavrMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue").then(m => m.default || m)
  },
  {
    name: indexnLRUBj7RQ9Meta?.name ?? "point-of-sale-account-configurations-id",
    path: indexnLRUBj7RQ9Meta?.path ?? "",
    meta: indexnLRUBj7RQ9Meta || {},
    alias: indexnLRUBj7RQ9Meta?.alias || [],
    redirect: indexnLRUBj7RQ9Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexyoB7OROUh5Meta?.name ?? "point-of-sale-account-configurations",
    path: indexyoB7OROUh5Meta?.path ?? "/point-of-sale/account-configurations",
    meta: indexyoB7OROUh5Meta || {},
    alias: indexyoB7OROUh5Meta?.alias || [],
    redirect: indexyoB7OROUh5Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/account-configurations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931ee12XdOZEMeta?.name ?? undefined,
    path: _91id_931ee12XdOZEMeta?.path ?? "/point-of-sale/connections/:id()",
    meta: _91id_931ee12XdOZEMeta || {},
    alias: _91id_931ee12XdOZEMeta?.alias || [],
    redirect: _91id_931ee12XdOZEMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91configurationId_93pVAyKAlCcxMeta?.name ?? "point-of-sale-connections-id-bookkeeping-configurationId",
    path: _91configurationId_93pVAyKAlCcxMeta?.path ?? "bookkeeping/:configurationId()",
    meta: _91configurationId_93pVAyKAlCcxMeta || {},
    alias: _91configurationId_93pVAyKAlCcxMeta?.alias || [],
    redirect: _91configurationId_93pVAyKAlCcxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/bookkeeping/[configurationId].vue").then(m => m.default || m)
  },
  {
    name: indexC8tieuy8zXMeta?.name ?? "point-of-sale-connections-id-bookkeeping",
    path: indexC8tieuy8zXMeta?.path ?? "bookkeeping",
    meta: indexC8tieuy8zXMeta || {},
    alias: indexC8tieuy8zXMeta?.alias || [],
    redirect: indexC8tieuy8zXMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/bookkeeping/index.vue").then(m => m.default || m)
  },
  {
    name: credentialsC1FfZOAl28Meta?.name ?? "point-of-sale-connections-id-credentials",
    path: credentialsC1FfZOAl28Meta?.path ?? "credentials",
    meta: credentialsC1FfZOAl28Meta || {},
    alias: credentialsC1FfZOAl28Meta?.alias || [],
    redirect: credentialsC1FfZOAl28Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/credentials.vue").then(m => m.default || m)
  },
  {
    name: index5yZvDBxbwFMeta?.name ?? "point-of-sale-connections-id",
    path: index5yZvDBxbwFMeta?.path ?? "",
    meta: index5yZvDBxbwFMeta || {},
    alias: index5yZvDBxbwFMeta?.alias || [],
    redirect: index5yZvDBxbwFMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: order_45providersHjqOPx2kPWMeta?.name ?? "point-of-sale-connections-id-order-providers",
    path: order_45providersHjqOPx2kPWMeta?.path ?? "order-providers",
    meta: order_45providersHjqOPx2kPWMeta || {},
    alias: order_45providersHjqOPx2kPWMeta?.alias || [],
    redirect: order_45providersHjqOPx2kPWMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/[id]/order-providers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91driver_93Hj145pSQkHMeta?.name ?? "point-of-sale-connections-create-driver",
    path: _91driver_93Hj145pSQkHMeta?.path ?? "/point-of-sale/connections/create/:driver()",
    meta: _91driver_93Hj145pSQkHMeta || {},
    alias: _91driver_93Hj145pSQkHMeta?.alias || [],
    redirect: _91driver_93Hj145pSQkHMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/create/[driver].vue").then(m => m.default || m)
  },
  {
    name: indexmgEKlUvgeBMeta?.name ?? "point-of-sale-connections-create",
    path: indexmgEKlUvgeBMeta?.path ?? "/point-of-sale/connections/create",
    meta: indexmgEKlUvgeBMeta || {},
    alias: indexmgEKlUvgeBMeta?.alias || [],
    redirect: indexmgEKlUvgeBMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUgVaDJrcRMeta?.name ?? "point-of-sale-connections",
    path: indexdUgVaDJrcRMeta?.path ?? "/point-of-sale/connections",
    meta: indexdUgVaDJrcRMeta || {},
    alias: indexdUgVaDJrcRMeta?.alias || [],
    redirect: indexdUgVaDJrcRMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/connections/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93M4GDw5fIoQMeta?.name ?? "point-of-sale-discounts-discounts-id",
    path: _91id_93M4GDw5fIoQMeta?.path ?? "/point-of-sale/discounts/discounts/:id()",
    meta: _91id_93M4GDw5fIoQMeta || {},
    alias: _91id_93M4GDw5fIoQMeta?.alias || [],
    redirect: _91id_93M4GDw5fIoQMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/discounts/[id].vue").then(m => m.default || m)
  },
  {
    name: index7c6hXQwOUSMeta?.name ?? "point-of-sale-discounts-discounts",
    path: index7c6hXQwOUSMeta?.path ?? "/point-of-sale/discounts/discounts",
    meta: index7c6hXQwOUSMeta || {},
    alias: index7c6hXQwOUSMeta?.alias || [],
    redirect: index7c6hXQwOUSMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbiBg4qo2nMeta?.name ?? "point-of-sale-discounts",
    path: indexFbiBg4qo2nMeta?.path ?? "/point-of-sale/discounts",
    meta: indexFbiBg4qo2nMeta || {},
    alias: indexFbiBg4qo2nMeta?.alias || [],
    redirect: indexFbiBg4qo2nMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BS3HTDYeW1Meta?.name ?? undefined,
    path: _91id_93BS3HTDYeW1Meta?.path ?? "/point-of-sale/employees/:id()",
    meta: _91id_93BS3HTDYeW1Meta || {},
    alias: _91id_93BS3HTDYeW1Meta?.alias || [],
    redirect: _91id_93BS3HTDYeW1Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexgU2mjHobvyMeta?.name ?? "point-of-sale-employees-id",
    path: indexgU2mjHobvyMeta?.path ?? "",
    meta: indexgU2mjHobvyMeta || {},
    alias: indexgU2mjHobvyMeta?.alias || [],
    redirect: indexgU2mjHobvyMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: lognpgV8S9fXuMeta?.name ?? "point-of-sale-employees-id-log",
    path: lognpgV8S9fXuMeta?.path ?? "log",
    meta: lognpgV8S9fXuMeta || {},
    alias: lognpgV8S9fXuMeta?.alias || [],
    redirect: lognpgV8S9fXuMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/[id]/log.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFX5rIk0cjdMeta?.name ?? "point-of-sale-employees",
    path: indexFX5rIk0cjdMeta?.path ?? "/point-of-sale/employees",
    meta: indexFX5rIk0cjdMeta || {},
    alias: indexFX5rIk0cjdMeta?.alias || [],
    redirect: indexFX5rIk0cjdMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/employees/index.vue").then(m => m.default || m)
  },
  {
    name: indexPjJgf8q2PsMeta?.name ?? "point-of-sale-images",
    path: indexPjJgf8q2PsMeta?.path ?? "/point-of-sale/images",
    meta: indexPjJgf8q2PsMeta || {},
    alias: indexPjJgf8q2PsMeta?.alias || [],
    redirect: indexPjJgf8q2PsMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/images/index.vue").then(m => m.default || m)
  },
  {
    name: indexsBMwvLUu2SMeta?.name ?? "point-of-sale-payments",
    path: indexsBMwvLUu2SMeta?.path ?? "/point-of-sale/payments",
    meta: indexsBMwvLUu2SMeta || {},
    alias: indexsBMwvLUu2SMeta?.alias || [],
    redirect: indexsBMwvLUu2SMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/payments/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsnrg97TZU7EMeta?.name ?? "point-of-sale-payments-payment-methods",
    path: payment_45methodsnrg97TZU7EMeta?.path ?? "/point-of-sale/payments/payment-methods",
    meta: payment_45methodsnrg97TZU7EMeta || {},
    alias: payment_45methodsnrg97TZU7EMeta?.alias || [],
    redirect: payment_45methodsnrg97TZU7EMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/payments/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: configurations5sMx8eiF94Meta?.name ?? "point-of-sale-reservations-configurations",
    path: configurations5sMx8eiF94Meta?.path ?? "/point-of-sale/reservations/configurations",
    meta: configurations5sMx8eiF94Meta || {},
    alias: configurations5sMx8eiF94Meta?.alias || [],
    redirect: configurations5sMx8eiF94Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reservations/configurations.vue").then(m => m.default || m)
  },
  {
    name: indexS9gl11aNryMeta?.name ?? "point-of-sale-reservations",
    path: indexS9gl11aNryMeta?.path ?? "/point-of-sale/reservations",
    meta: indexS9gl11aNryMeta || {},
    alias: indexS9gl11aNryMeta?.alias || [],
    redirect: indexS9gl11aNryMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: configurations6yrX9rmR8hMeta?.name ?? "point-of-sale-reviews-configurations",
    path: configurations6yrX9rmR8hMeta?.path ?? "/point-of-sale/reviews/configurations",
    meta: configurations6yrX9rmR8hMeta || {},
    alias: configurations6yrX9rmR8hMeta?.alias || [],
    redirect: configurations6yrX9rmR8hMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reviews/configurations.vue").then(m => m.default || m)
  },
  {
    name: index6DB5lzoS4KMeta?.name ?? "point-of-sale-reviews",
    path: index6DB5lzoS4KMeta?.path ?? "/point-of-sale/reviews",
    meta: index6DB5lzoS4KMeta || {},
    alias: index6DB5lzoS4KMeta?.alias || [],
    redirect: index6DB5lzoS4KMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QkpZgrrjxhMeta?.name ?? "point-of-sale-stock-categories-id",
    path: _91id_93QkpZgrrjxhMeta?.path ?? "/point-of-sale/stock-categories/:id()",
    meta: _91id_93QkpZgrrjxhMeta || {},
    alias: _91id_93QkpZgrrjxhMeta?.alias || [],
    redirect: _91id_93QkpZgrrjxhMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93WpTrzY5BFGMeta?.name ?? "point-of-sale-stock-items-id",
    path: _91id_93WpTrzY5BFGMeta?.path ?? "/point-of-sale/stock-items/:id()",
    meta: _91id_93WpTrzY5BFGMeta || {},
    alias: _91id_93WpTrzY5BFGMeta?.alias || [],
    redirect: _91id_93WpTrzY5BFGMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-items/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cKJseGZVBvMeta?.name ?? "point-of-sale-stock-statuses-id",
    path: _91id_93cKJseGZVBvMeta?.path ?? "/point-of-sale/stock-statuses/:id()",
    meta: _91id_93cKJseGZVBvMeta || {},
    alias: _91id_93cKJseGZVBvMeta?.alias || [],
    redirect: _91id_93cKJseGZVBvMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock-statuses/[id].vue").then(m => m.default || m)
  },
  {
    name: stockKib9brzosqMeta?.name ?? "point-of-sale-stock",
    path: stockKib9brzosqMeta?.path ?? "/point-of-sale/stock",
    meta: stockKib9brzosqMeta || {},
    alias: stockKib9brzosqMeta?.alias || [],
    redirect: stockKib9brzosqMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stock.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lAF147RpGBMeta?.name ?? undefined,
    path: _91id_93lAF147RpGBMeta?.path ?? "/point-of-sale/stores/:id()",
    meta: _91id_93lAF147RpGBMeta || {},
    alias: _91id_93lAF147RpGBMeta?.alias || [],
    redirect: _91id_93lAF147RpGBMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id].vue").then(m => m.default || m),
    children: [
  {
    name: bookkeepingWUR3scVhbIMeta?.name ?? "point-of-sale-stores-id-bookkeeping",
    path: bookkeepingWUR3scVhbIMeta?.path ?? "bookkeeping",
    meta: bookkeepingWUR3scVhbIMeta || {},
    alias: bookkeepingWUR3scVhbIMeta?.alias || [],
    redirect: bookkeepingWUR3scVhbIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/bookkeeping.vue").then(m => m.default || m)
  },
  {
    name: _91floorId_93Quoo98HYYyMeta?.name ?? "point-of-sale-stores-id-floors-floorId",
    path: _91floorId_93Quoo98HYYyMeta?.path ?? "floors/:floorId()",
    meta: _91floorId_93Quoo98HYYyMeta || {},
    alias: _91floorId_93Quoo98HYYyMeta?.alias || [],
    redirect: _91floorId_93Quoo98HYYyMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/floors/[floorId].vue").then(m => m.default || m)
  },
  {
    name: indext5iATorndYMeta?.name ?? "point-of-sale-stores-id-floors",
    path: indext5iATorndYMeta?.path ?? "floors",
    meta: indext5iATorndYMeta || {},
    alias: indext5iATorndYMeta?.alias || [],
    redirect: indext5iATorndYMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/floors/index.vue").then(m => m.default || m)
  },
  {
    name: indexjb6du2QHSOMeta?.name ?? "point-of-sale-stores-id",
    path: indexjb6du2QHSOMeta?.path ?? "",
    meta: indexjb6du2QHSOMeta || {},
    alias: indexjb6du2QHSOMeta?.alias || [],
    redirect: indexjb6du2QHSOMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextxrx9AbuNNMeta?.name ?? "point-of-sale-stores-id-kitchen",
    path: indextxrx9AbuNNMeta?.path ?? "kitchen",
    meta: indextxrx9AbuNNMeta || {},
    alias: indextxrx9AbuNNMeta?.alias || [],
    redirect: indextxrx9AbuNNMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/kitchen/index.vue").then(m => m.default || m)
  },
  {
    name: _91kitchenStationId_93Pw47uE6jpSMeta?.name ?? "point-of-sale-stores-id-kitchen-kitchen-stations-kitchenStationId",
    path: _91kitchenStationId_93Pw47uE6jpSMeta?.path ?? "kitchen/kitchen-stations/:kitchenStationId()",
    meta: _91kitchenStationId_93Pw47uE6jpSMeta || {},
    alias: _91kitchenStationId_93Pw47uE6jpSMeta?.alias || [],
    redirect: _91kitchenStationId_93Pw47uE6jpSMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue").then(m => m.default || m)
  },
  {
    name: _91printerId_93keLv8EMNMlMeta?.name ?? "point-of-sale-stores-id-printers-printerId",
    path: _91printerId_93keLv8EMNMlMeta?.path ?? "printers/:printerId()",
    meta: _91printerId_93keLv8EMNMlMeta || {},
    alias: _91printerId_93keLv8EMNMlMeta?.alias || [],
    redirect: _91printerId_93keLv8EMNMlMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/printers/[printerId].vue").then(m => m.default || m)
  },
  {
    name: index83vWeGwCzoMeta?.name ?? "point-of-sale-stores-id-printers",
    path: index83vWeGwCzoMeta?.path ?? "printers",
    meta: index83vWeGwCzoMeta || {},
    alias: index83vWeGwCzoMeta?.alias || [],
    redirect: index83vWeGwCzoMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/printers/index.vue").then(m => m.default || m)
  },
  {
    name: stockfkWBWTzOzFMeta?.name ?? "point-of-sale-stores-id-stock",
    path: stockfkWBWTzOzFMeta?.path ?? "stock",
    meta: stockfkWBWTzOzFMeta || {},
    alias: stockfkWBWTzOzFMeta?.alias || [],
    redirect: stockfkWBWTzOzFMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/[id]/stock.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexyWntmRXPBDMeta?.name ?? "point-of-sale-stores",
    path: indexyWntmRXPBDMeta?.path ?? "/point-of-sale/stores",
    meta: indexyWntmRXPBDMeta || {},
    alias: indexyWntmRXPBDMeta?.alias || [],
    redirect: indexyWntmRXPBDMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/stores/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6RtZTwHlPMeta?.name ?? "point-of-sale-terminals-colors",
    path: indexB6RtZTwHlPMeta?.path ?? "/point-of-sale/terminals/colors",
    meta: indexB6RtZTwHlPMeta || {},
    alias: indexB6RtZTwHlPMeta?.alias || [],
    redirect: indexB6RtZTwHlPMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/colors/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zLoS556VqMMeta?.name ?? "point-of-sale-terminals-folders-id",
    path: _91id_93zLoS556VqMMeta?.path ?? "/point-of-sale/terminals/folders/:id()",
    meta: _91id_93zLoS556VqMMeta || {},
    alias: _91id_93zLoS556VqMMeta?.alias || [],
    redirect: _91id_93zLoS556VqMMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/folders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexahLFDeMjJAMeta?.name ?? "point-of-sale-terminals-folders",
    path: indexahLFDeMjJAMeta?.path ?? "/point-of-sale/terminals/folders",
    meta: indexahLFDeMjJAMeta || {},
    alias: indexahLFDeMjJAMeta?.alias || [],
    redirect: indexahLFDeMjJAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/folders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Kexp4rb7wfMeta?.name ?? undefined,
    path: _91id_93Kexp4rb7wfMeta?.path ?? "/point-of-sale/terminals/terminals/:id()",
    meta: _91id_93Kexp4rb7wfMeta || {},
    alias: _91id_93Kexp4rb7wfMeta?.alias || [],
    redirect: _91id_93Kexp4rb7wfMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id].vue").then(m => m.default || m),
    children: [
  {
    name: index7vRZZn1toAMeta?.name ?? "point-of-sale-terminals-terminals-id",
    path: index7vRZZn1toAMeta?.path ?? "",
    meta: index7vRZZn1toAMeta || {},
    alias: index7vRZZn1toAMeta?.alias || [],
    redirect: index7vRZZn1toAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: log4wGZlnOpstMeta?.name ?? "point-of-sale-terminals-terminals-id-log",
    path: log4wGZlnOpstMeta?.path ?? "log",
    meta: log4wGZlnOpstMeta || {},
    alias: log4wGZlnOpstMeta?.alias || [],
    redirect: log4wGZlnOpstMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/log.vue").then(m => m.default || m)
  },
  {
    name: navigationn6QgWIoVwoMeta?.name ?? "point-of-sale-terminals-terminals-id-navigation",
    path: navigationn6QgWIoVwoMeta?.path ?? "navigation",
    meta: navigationn6QgWIoVwoMeta || {},
    alias: navigationn6QgWIoVwoMeta?.alias || [],
    redirect: navigationn6QgWIoVwoMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/[id]/navigation.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index97PFsYMYvgMeta?.name ?? "point-of-sale-terminals-terminals",
    path: index97PFsYMYvgMeta?.path ?? "/point-of-sale/terminals/terminals",
    meta: index97PFsYMYvgMeta || {},
    alias: index97PFsYMYvgMeta?.alias || [],
    redirect: index97PFsYMYvgMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/point-of-sale/terminals/terminals/index.vue").then(m => m.default || m)
  },
  {
    name: index1pTW3pC4ZAMeta?.name ?? "profile",
    path: index1pTW3pC4ZAMeta?.path ?? "/profile",
    meta: index1pTW3pC4ZAMeta || {},
    alias: index1pTW3pC4ZAMeta?.alias || [],
    redirect: index1pTW3pC4ZAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: downloadimpehwiDAzMeta?.name ?? "reports-download",
    path: downloadimpehwiDAzMeta?.path ?? "/reports/download",
    meta: downloadimpehwiDAzMeta || {},
    alias: downloadimpehwiDAzMeta?.alias || [],
    redirect: downloadimpehwiDAzMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/download.vue").then(m => m.default || m)
  },
  {
    name: efficiencydiNRZyGZJwMeta?.name ?? "reports-efficiency",
    path: efficiencydiNRZyGZJwMeta?.path ?? "/reports/efficiency",
    meta: efficiencydiNRZyGZJwMeta || {},
    alias: efficiencydiNRZyGZJwMeta?.alias || [],
    redirect: efficiencydiNRZyGZJwMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/efficiency.vue").then(m => m.default || m)
  },
  {
    name: indexZTcUmHYoLxMeta?.name ?? "reports",
    path: indexZTcUmHYoLxMeta?.path ?? "/reports",
    meta: indexZTcUmHYoLxMeta || {},
    alias: indexZTcUmHYoLxMeta?.alias || [],
    redirect: indexZTcUmHYoLxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: production1DxfG19jrxMeta?.name ?? "reports-production",
    path: production1DxfG19jrxMeta?.path ?? "/reports/production",
    meta: production1DxfG19jrxMeta || {},
    alias: production1DxfG19jrxMeta?.alias || [],
    redirect: production1DxfG19jrxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/production.vue").then(m => m.default || m)
  },
  {
    name: tipsZUGEvm7zXnMeta?.name ?? "reports-tips",
    path: tipsZUGEvm7zXnMeta?.path ?? "/reports/tips",
    meta: tipsZUGEvm7zXnMeta || {},
    alias: tipsZUGEvm7zXnMeta?.alias || [],
    redirect: tipsZUGEvm7zXnMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/reports/tips.vue").then(m => m.default || m)
  },
  {
    name: index2cH31B9EsmMeta?.name ?? "settings",
    path: index2cH31B9EsmMeta?.path ?? "/settings",
    meta: index2cH31B9EsmMeta || {},
    alias: index2cH31B9EsmMeta?.alias || [],
    redirect: index2cH31B9EsmMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexm1OPuzePS7Meta?.name ?? "settlement-cancellations",
    path: indexm1OPuzePS7Meta?.path ?? "/settlement/cancellations",
    meta: indexm1OPuzePS7Meta || {},
    alias: indexm1OPuzePS7Meta?.alias || [],
    redirect: indexm1OPuzePS7Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cancellations/index.vue").then(m => m.default || m)
  },
  {
    name: reasonsjBaVkESK4aMeta?.name ?? "settlement-cancellations-reasons",
    path: reasonsjBaVkESK4aMeta?.path ?? "/settlement/cancellations/reasons",
    meta: reasonsjBaVkESK4aMeta || {},
    alias: reasonsjBaVkESK4aMeta?.alias || [],
    redirect: reasonsjBaVkESK4aMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cancellations/reasons.vue").then(m => m.default || m)
  },
  {
    name: cash_45transferstgqTG7dIKGMeta?.name ?? "settlement-cash-transfers-cash-transfers",
    path: cash_45transferstgqTG7dIKGMeta?.path ?? "/settlement/cash-transfers/cash-transfers",
    meta: cash_45transferstgqTG7dIKGMeta || {},
    alias: cash_45transferstgqTG7dIKGMeta?.alias || [],
    redirect: cash_45transferstgqTG7dIKGMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/cash-transfers.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yXmsdQdMosMeta?.name ?? "settlement-cash-transfers-categories-id",
    path: _91id_93yXmsdQdMosMeta?.path ?? "/settlement/cash-transfers/categories/:id()",
    meta: _91id_93yXmsdQdMosMeta || {},
    alias: _91id_93yXmsdQdMosMeta?.alias || [],
    redirect: _91id_93yXmsdQdMosMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRAj6aFsknAMeta?.name ?? "settlement-cash-transfers-categories",
    path: indexRAj6aFsknAMeta?.path ?? "/settlement/cash-transfers/categories",
    meta: indexRAj6aFsknAMeta || {},
    alias: indexRAj6aFsknAMeta?.alias || [],
    redirect: indexRAj6aFsknAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/categories/index.vue").then(m => m.default || m)
  },
  {
    name: index9T84xJDuskMeta?.name ?? "settlement-cash-transfers",
    path: index9T84xJDuskMeta?.path ?? "/settlement/cash-transfers",
    meta: index9T84xJDuskMeta || {},
    alias: index9T84xJDuskMeta?.alias || [],
    redirect: index9T84xJDuskMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/cash-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U1jphQYTUHMeta?.name ?? undefined,
    path: _91id_93U1jphQYTUHMeta?.path ?? "/settlement/channel-settlements/:id()",
    meta: _91id_93U1jphQYTUHMeta || {},
    alias: _91id_93U1jphQYTUHMeta?.alias || [],
    redirect: _91id_93U1jphQYTUHMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexKFEIRmkm7dMeta?.name ?? "settlement-channel-settlements-id",
    path: indexKFEIRmkm7dMeta?.path ?? "",
    meta: indexKFEIRmkm7dMeta || {},
    alias: indexKFEIRmkm7dMeta?.alias || [],
    redirect: indexKFEIRmkm7dMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: logrZuTmkNBwgMeta?.name ?? "settlement-channel-settlements-id-log",
    path: logrZuTmkNBwgMeta?.path ?? "log",
    meta: logrZuTmkNBwgMeta || {},
    alias: logrZuTmkNBwgMeta?.alias || [],
    redirect: logrZuTmkNBwgMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/log.vue").then(m => m.default || m)
  },
  {
    name: ordershdYu5R5DJLMeta?.name ?? "settlement-channel-settlements-id-orders",
    path: ordershdYu5R5DJLMeta?.path ?? "orders",
    meta: ordershdYu5R5DJLMeta || {},
    alias: ordershdYu5R5DJLMeta?.alias || [],
    redirect: ordershdYu5R5DJLMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/orders.vue").then(m => m.default || m)
  },
  {
    name: _91channelSettlementSnapshotId_93pGQWIGnCGIMeta?.name ?? "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: _91channelSettlementSnapshotId_93pGQWIGnCGIMeta?.path ?? "snapshots/:channelSettlementSnapshotId()",
    meta: _91channelSettlementSnapshotId_93pGQWIGnCGIMeta || {},
    alias: _91channelSettlementSnapshotId_93pGQWIGnCGIMeta?.alias || [],
    redirect: _91channelSettlementSnapshotId_93pGQWIGnCGIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue").then(m => m.default || m)
  },
  {
    name: indexR7WcrIDqimMeta?.name ?? "settlement-channel-settlements-id-snapshots",
    path: indexR7WcrIDqimMeta?.path ?? "snapshots",
    meta: indexR7WcrIDqimMeta || {},
    alias: indexR7WcrIDqimMeta?.alias || [],
    redirect: indexR7WcrIDqimMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/channel-settlements/[id]/snapshots/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93hd1XPCGnT4Meta?.name ?? "settlement-delivery-providers-id",
    path: _91id_93hd1XPCGnT4Meta?.path ?? "/settlement/delivery-providers/:id()",
    meta: _91id_93hd1XPCGnT4Meta || {},
    alias: _91id_93hd1XPCGnT4Meta?.alias || [],
    redirect: _91id_93hd1XPCGnT4Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/delivery-providers/[id].vue").then(m => m.default || m)
  },
  {
    name: index4iAhA7GtZVMeta?.name ?? "settlement-delivery-providers",
    path: index4iAhA7GtZVMeta?.path ?? "/settlement/delivery-providers",
    meta: index4iAhA7GtZVMeta || {},
    alias: index4iAhA7GtZVMeta?.alias || [],
    redirect: index4iAhA7GtZVMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/delivery-providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iJ1fXWe8byMeta?.name ?? "settlement-order-providers-id",
    path: _91id_93iJ1fXWe8byMeta?.path ?? "/settlement/order-providers/:id()",
    meta: _91id_93iJ1fXWe8byMeta || {},
    alias: _91id_93iJ1fXWe8byMeta?.alias || [],
    redirect: _91id_93iJ1fXWe8byMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/order-providers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoLPDtdwlwgMeta?.name ?? "settlement-order-providers",
    path: indexoLPDtdwlwgMeta?.path ?? "/settlement/order-providers",
    meta: indexoLPDtdwlwgMeta || {},
    alias: indexoLPDtdwlwgMeta?.alias || [],
    redirect: indexoLPDtdwlwgMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/order-providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wmCVLINy6TMeta?.name ?? "settlement-orders-id",
    path: _91id_93wmCVLINy6TMeta?.path ?? "/settlement/orders/:id()",
    meta: _91id_93wmCVLINy6TMeta || {},
    alias: _91id_93wmCVLINy6TMeta?.alias || [],
    redirect: _91id_93wmCVLINy6TMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKJ3MBysQHKMeta?.name ?? "settlement-orders",
    path: indexKJ3MBysQHKMeta?.path ?? "/settlement/orders",
    meta: indexKJ3MBysQHKMeta || {},
    alias: indexKJ3MBysQHKMeta?.alias || [],
    redirect: indexKJ3MBysQHKMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TnfYtXcpI7Meta?.name ?? undefined,
    path: _91id_93TnfYtXcpI7Meta?.path ?? "/settlement/store-settlements/:id()",
    meta: _91id_93TnfYtXcpI7Meta || {},
    alias: _91id_93TnfYtXcpI7Meta?.alias || [],
    redirect: _91id_93TnfYtXcpI7Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id].vue").then(m => m.default || m),
    children: [
  {
    name: channel_45settlementszB3EefEBjoMeta?.name ?? "settlement-store-settlements-id-channel-settlements",
    path: channel_45settlementszB3EefEBjoMeta?.path ?? "channel-settlements",
    meta: channel_45settlementszB3EefEBjoMeta || {},
    alias: channel_45settlementszB3EefEBjoMeta?.alias || [],
    redirect: channel_45settlementszB3EefEBjoMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/channel-settlements.vue").then(m => m.default || m)
  },
  {
    name: indexGDoov4MetKMeta?.name ?? "settlement-store-settlements-id",
    path: indexGDoov4MetKMeta?.path ?? "",
    meta: indexGDoov4MetKMeta || {},
    alias: indexGDoov4MetKMeta?.alias || [],
    redirect: indexGDoov4MetKMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: logdtgAt6ATnXMeta?.name ?? "settlement-store-settlements-id-log",
    path: logdtgAt6ATnXMeta?.path ?? "log",
    meta: logdtgAt6ATnXMeta || {},
    alias: logdtgAt6ATnXMeta?.alias || [],
    redirect: logdtgAt6ATnXMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/log.vue").then(m => m.default || m)
  },
  {
    name: ordersvW1ZGX7iosMeta?.name ?? "settlement-store-settlements-id-orders",
    path: ordersvW1ZGX7iosMeta?.path ?? "orders",
    meta: ordersvW1ZGX7iosMeta || {},
    alias: ordersvW1ZGX7iosMeta?.alias || [],
    redirect: ordersvW1ZGX7iosMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/orders.vue").then(m => m.default || m)
  },
  {
    name: voucher_45exportszymXZsWN1IMeta?.name ?? "settlement-store-settlements-id-voucher-exports",
    path: voucher_45exportszymXZsWN1IMeta?.path ?? "voucher-exports",
    meta: voucher_45exportszymXZsWN1IMeta || {},
    alias: voucher_45exportszymXZsWN1IMeta?.alias || [],
    redirect: voucher_45exportszymXZsWN1IMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/[id]/voucher-exports.vue").then(m => m.default || m)
  }
]
  },
  {
    name: closing_45errors9vRREQXOK2Meta?.name ?? "settlement-store-settlements-closing-errors",
    path: closing_45errors9vRREQXOK2Meta?.path ?? "/settlement/store-settlements/closing-errors",
    meta: closing_45errors9vRREQXOK2Meta || {},
    alias: closing_45errors9vRREQXOK2Meta?.alias || [],
    redirect: closing_45errors9vRREQXOK2Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/closing-errors.vue").then(m => m.default || m)
  },
  {
    name: indexyD4S5kJfAjMeta?.name ?? "settlement-store-settlements",
    path: indexyD4S5kJfAjMeta?.path ?? "/settlement/store-settlements",
    meta: indexyD4S5kJfAjMeta || {},
    alias: indexyD4S5kJfAjMeta?.alias || [],
    redirect: indexyD4S5kJfAjMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241005201020/pages/settlement/store-settlements/index.vue").then(m => m.default || m)
  }
]